import React from 'react'

const Video = ({pageTitle, videoUrl}) => {
    return (
        <>
            <div className="my-container mx-auto px-4 grid" >
                <div className="aspect-w-16 aspect-h-9" style={{maxHeight:'650px'}}>
                    <iframe 
                        title={`${pageTitle}`}
                        src={`${videoUrl}`} 
                        style={{width:'100%'}} 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen></iframe>
                </div>
            </div>
        </>
    )
}

export default Video;