import React, { useState, useRef } from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './page-vixens.module.css'
import ZoomIcon from '../../../assets/icon-zoom.svg'
import { MdClose, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import Video from '../../components/Video'
import { SocialMediaLinks } from '../../utils'


const PageVixens = ({ pageContext, location }) => {
    const page = pageContext.page

    const [showLightbox, setShowLightbox] = useState(false)
    const [imgIndex, setImgIndex] = useState(0)
    const lightboxRef = useRef()

    const data = useStaticQuery(graphql`
    query {
        vixens: file(relativePath: {eq: "vixens-w570.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 570
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        wildside: file(relativePath: {eq: "vixens-the-wild-side.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 502
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenslogo: file(relativePath: {eq: "premiere-vixens-logo.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 470
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }



        vixenSJ: file(relativePath: {eq: "sj-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenSJFull: file(relativePath: {eq: "sj-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenMaine: file(relativePath: {eq: "maine-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenMaineFull: file(relativePath: {eq: "maine-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenJoanne: file(relativePath: {eq: "joanne-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenJoanneFull: file(relativePath: {eq: "joanne-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenLuz: file(relativePath: {eq: "luz-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenLuzFull: file(relativePath: {eq: "luz-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenArra: file(relativePath: {eq: "arra-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenArraFull: file(relativePath: {eq: "arra-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenRobilyn: file(relativePath: {eq: "robilyn-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        vixenRobilynFull: file(relativePath: {eq: "robilyn-w800.jpg"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
    }
    `)

    const vixens = [
        { ...data.vixenSJ },
        { ...data.vixenMaine },
        { ...data.vixenJoanne },
        { ...data.vixenLuz },
        { ...data.vixenArra },
        { ...data.vixenRobilyn },
    ]
    // Same order with vixens array
    const vixensFull = [
        { ...data.vixenSJFull },
        { ...data.vixenMaineFull },
        { ...data.vixenJoanneFull },
        { ...data.vixenLuzFull },
        { ...data.vixenArraFull },
        { ...data.vixenRobilynFull },
    ]
    
    const itemClicked = (e, index) => {
        e.preventDefault()
        setImgIndex(index)
        setShowLightbox(true)
        return false
    }

    const onClickLightboxBackground = (e) => {
        e.preventDefault()
        if (e.target === lightboxRef.current) {
            setShowLightbox(false)
        }
    }

    const onNext = (e,index) => {
        e.preventDefault()
        index = index >= vixensFull.length - 1 ? 0 : index + 1;
        setImgIndex(index)
    }

    const onPrev = (e,index) => {
        e.preventDefault()
        index = index === 0? vixensFull.length - 1 : index - 1;
        setImgIndex(index);
    }

    return (
        <Layout>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href }
            ].concat(SocialMediaLinks(data.vixenslogo?.childImageSharp.original.src))}/>
            
            <Video pageTitle={`${page.title} Youtube`} videoUrl={`https://www.youtube.com/embed/V8exGaviDVo?autoplay=1&enablejsapi=1&autohide=0&controls=1&rel=0&showinfo=0&loop=1`}/>

            <div className="mx-auto mt-20 my-container ">
                <div className="relative flex flex-col justify-center md:flex-row">
                    <div className="flex items-center self-stretch justify-center flex-grow md:self-auto md:order-2">
                        <GatsbyImage image={data.vixens.childImageSharp.gatsbyImageData} style={{width:'100%', margin:'auto', maxWidth:'570px'}} alt="Vixens"/>
                    </div>

                    <div className="flex-grow md:flex md:flex-col md:order-1">
                        <div className="flex px-10 py-5">
                            <GatsbyImage image={data.vixenslogo.childImageSharp.gatsbyImageData} style={{width:'80%', margin:'auto', maxWidth:'470px'}} alt="Vixens Logo"/>
                        </div>
                        <div className={`z-50 md:relative flex flex-col absolute left-0 px-24 md:px-10 md:m-auto w-full md:items-end ${styles.wildside}`}>
                            <GatsbyImage image={data.wildside.childImageSharp.gatsbyImageData} style={{width:'100%', margin:'auto', maxWidth:'502px'}} alt="Walk on the wild side"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mx-auto my-20 my-container">
                <div className="grid grid-cols-2 gap-5 px-2 mx-auto md:grid-cols-3 md:gap-10" style={{maxWidth:'1140px', width:'100%'}}>
                    {vixens.map((itm, index) => (
                        <div key={`p${itm.id}`} className="relative border-4 border-white">
                            <GatsbyImage key={itm.id} image={itm.childImageSharp.gatsbyImageData} alt={`Vixen ${index + 1} Thumbnail Size`}/>
                            <a href={`#${itm.id}`} onClick={e => itemClicked(e, index)} className="absolute top-0 left-0 flex w-full h-full opacity-0 hover:bg-black hover:bg-opacity-40 hover:opacity-100">
                                <ZoomIcon className="w-16 m-auto"/>
                            </a>
                        </div>
                    ))}
                </div>
            </div>

            {showLightbox?
            <div onClick={(e) => onClickLightboxBackground(e)} ref={lightboxRef} 
                 className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-black bg-opacity-80 backdrop-blur-md"
                 style={{zIndex:999999999}}>
                {vixensFull.map((itm, index) => {
                    if (index === imgIndex) {
                        return (
                        <div key={itm.id} style={{maxHeight:'100%', maxWidth:'90%', width:'600px'}} className="relative">
                            <div className="border-4 border-white rounded">
                                <GatsbyImage image={itm.childImageSharp.gatsbyImageData} alt={`Vixen ${index + 1} Large Size`}/>
                            </div>

                            <button onClick={(e) => onPrev(e,index)} 
                                className="absolute left-0 w-10 h-32 transform -translate-y-1/2 rounded-l-md top-1/2 md:bg-white md:w-10 md:h-24 md:right-full md:left-auto">
                                    <MdKeyboardArrowLeft className="w-full h-full text-yellow-500 md:text-gray-400 text-opacity-80"/>
                            </button>
                            <button onClick={(e) => onNext(e,index)} 
                                className="absolute right-0 w-10 h-32 transform -translate-y-1/2 rounded-r-md top-1/2 md:bg-white md:w-10 md:h-24 md:left-full md:right-auto">
                                    <MdKeyboardArrowRight className="w-full h-full text-yellow-500 md:text-gray-400 text-opacity-80"/>
                            </button>

                            <button onClick={() => setShowLightbox(false)} className="absolute top-0 right-0 bg-white rounded-full w-7 h-7" style={{transform:'translateY(-50%) translateX(50%)'}}>
                                <MdClose className="w-full h-full text-gray-400" />
                            </button>
                        </div>)
                    }
                    return null
                })}
            </div>
            :null}
        </Layout>
    )
}

export default PageVixens
